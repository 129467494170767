/**
 * https://mui.com/material-ui/api/list/
 */
const MuiList = {
  defaultProps: {
    disablePadding: true,
  },
};

export default MuiList;
