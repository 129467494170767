const DS_palette = {
  black: {
    900: '#000000',
  },

  white: {
    0: 'rgba(255, 255, 255, 0.2)',
    50: '#FFFFFF',
  },

  grey: {
    50: '#F8F8F8',
    100: '#DFE1EA',
    200: '#BBBBBB',
    300: '#717171',
  },

  blue: {
    50: '#FAFBFF',
    100: '#E1F1FF',
    500: '#266FF2',
    600: '#0059FA',
  },

  flamingo: {
    50: '#FFF4F9',
    100: '#EA79A6',
    200: '#E66096',
  },

  mint: {
    50: '#EAFCF9',
    100: '#73D3C1',
    200: '#1B7E7E',
  },

  pink: {
    50: '#E692A7',
    100: '#BE6876',
  },

  marketing: {
    pink: '#F090AC',
  },

  states: {
    error: '#FB232E',
  },
};

export default DS_palette;
