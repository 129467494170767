/**
 * https://mui.com/material-ui/api/button/
 */
const MuiButton = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    disableFocusRipple: true,
  },
};

export default MuiButton;
