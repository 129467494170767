import * as Sentry from '@sentry/gatsby';
import packageJson from './package.json';

//change to master when ready to deploy

if (process.env.GATSBY_SENTRY_BRANCH === 'master')
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    maxBreadcrumbs: 10,
    release: `${process.env.GATSBY_SENTRY_PROJECT}@${packageJson.version}`,
    debug: false,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 0.1,
    environment: process.env.GATSBY_SENTRY_BRANCH,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSendTransaction(event) {
      return event;
    },
  });
