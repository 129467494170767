const DS_border = {
  radius: {
    sm: '6px',
    md: '10px',
    lg: '12px',
    xl: '24px',
    xxl: '50px',
  },
  width: {
    xs: '1px',
    sm: '2px',
  },
};

export default DS_border;
