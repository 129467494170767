import { DS_breakpoints as coreBreakpoints, DS_typography as coreTypography } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.breakpoints
 */
const DS_breakpoints = {
  unit: coreBreakpoints.unit,
  values: {
    xs: coreBreakpoints.xs,
    md: coreBreakpoints.md,
    lg: coreBreakpoints.lg,
    xl: coreBreakpoints.xl,
  },
  px: {
    xs: coreBreakpoints.xs * coreTypography.fontSize.root,
    md: coreBreakpoints.md * coreTypography.fontSize.root,
    lg: coreBreakpoints.lg * coreTypography.fontSize.root,
    xl: coreBreakpoints.xl * coreTypography.fontSize.root,
  },
};

export default DS_breakpoints;
