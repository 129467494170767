import { spacing as coreSpacing } from '../core';
/**
 * @typedef {'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'} Size
 * @typedef {'rem' | 'em' | 'px'} Unit
 */

/**
 * https://mui.com/material-ui/customization/spacing/
 *
 * @param {[Size, Unit]} props
 *
 * @returns {String}
 */
const spacing = props => {
  /**
   * Accept MUI numbers to use on the grid spacing
   *
   */

  if (!isNaN(props)) return Number(props);

  /**
   * MUI allows for multiple comma separated spacing arguments to be sent
   * with a single call eg:
   *
   * padding: ${theme.spacing(top, right, bottom, left)}
   *
   * This means if we want to be able to define more than 1 custom prop
   * we need to wrap them in an array or object. We are using an array to
   * simplify the syntax eg:
   *
   * padding: ${theme.spacing(['md'], ['sm'])}
   */
  const [size, unit = 'rem'] = props;

  if (!coreSpacing[unit]) throw `Invalid spacing unit: ${unit}`;
  if (!coreSpacing[unit][size]) throw `Invalid spacing size: ${size}`;

  return coreSpacing[unit][size];
};

export default spacing;
