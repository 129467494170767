import { breakpoints as coreBreakpoints, typography as coreTypography } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.breakpoints
 */
const breakpoints = {
  unit: coreBreakpoints.unit,
  values: {
    phone: coreBreakpoints.tablet,
    tablet: coreBreakpoints.tablet,
    desktop: coreBreakpoints.desktop,
  },
  px: {
    phone: coreBreakpoints.tablet * coreTypography.fontSize.root,
    tablet: coreBreakpoints.tablet * coreTypography.fontSize.root,
    desktop: coreBreakpoints.desktop * coreTypography.fontSize.root,
  },
};

export default breakpoints;
