import OpenSansMediumWoff2 from './OpenSans-Medium.woff2';
import OpenSansBoldWoff2 from './OpenSans-Bold.woff2';
import OpenSansExtraBoldWoff2 from './OpenSans-ExtraBold.woff2';

const fonts = `
 
  @font-face {
    font-family: 'OpenSans';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url(${OpenSansMediumWoff2}) format('woff2');
  }
 
  @font-face { 
    @font-face {
    font-family: 'OpenSans';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url(${OpenSansBoldWoff2}) format('woff2');
  }
  @font-face {
    font-family: 'OpenSans';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url(${OpenSansExtraBoldWoff2}) format('woff2');
  }


`;

export default fonts;
export { OpenSansMediumWoff2, OpenSansBoldWoff2, OpenSansExtraBoldWoff2 };
