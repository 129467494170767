/**
 * https://mui.com/material-ui/api/drawer/
 */
const MuiDrawer = {
  defaultProps: {
    elevation: 0,
  },
};

export default MuiDrawer;
