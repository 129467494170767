/**
 * https://mui.com/material-ui/api/popover/
 */
const MuiPopover = {
  defaultProps: {
    elevation: 25,
  },
};

export default MuiPopover;
