const border = {
  radius: {
    sm: '4px',
  },
  width: {
    thin: '1px',
    thick: '3px',
  },
};

export default border;
