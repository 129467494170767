/**
 * https://mui.com/material-ui/api/link/
 */
const MuiLink = {
  defaultProps: {
    underline: 'none',
  },
};

export default MuiLink;
