import { layout as coreLayout } from '../core';

const layout = {
  grid: {
    maxWidth: coreLayout.container.maxWidth,
    maxWidthLg: coreLayout.container.maxWidthLg,
    maxWidthMd: coreLayout.container.maxWidthMd,
    maxWidthSm: coreLayout.container.maxWidthSm,
  },
};

export default layout;
