const locale = process.env.GATSBY_COUNTRY_CODE;

export const types = {
  book: 'BOOK',
  reschedule: 'RESCH',
};

/**
 * Creating gtm evetn for survey.
 * @param {String} eventAction
 * @returns {Object}
 */
const createGTMSurveyEvent = eventAction => ({
  event: 'gtmEvent',
  eventCategory: 'Survey',
  eventAction,
});

/**
 * Create event body for GTM event.
 * @param {String} eventAction
 * @returns {Object}
 */
const createGTMBookingEventBody = eventAction => ({
  event: 'gtmEvent',
  eventCategory: 'Booking funnel',
  isEventNonInteraction: 'False',
  eventAction,
});

export const getType = type => {
  const availableTypes = Object.keys(types);

  if (!availableTypes.includes(type)) return types.book;

  return types[type];
};

function pushToDataLayer(gtmEvent) {
  if (!window || !window.dataLayer) return;

  window.dataLayer.push(gtmEvent);
}
export function getPtsrcParam() {
  if (!window) return;
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.toString();
}

export function pushGTMBookingCancelledEvent({ location, service, cancelReason }) {
  const gtmEvent = {
    event: 'Appointment Cancelled',
    attributes: {
      ptid: 'contentful',
      location,
      service,
      cancelReason,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMClosingPopupComponent({ title, lang }) {
  const gtmEvent = {
    event: 'userClosedComponentPopup',
    attributes: {
      ptid: 'contentful',
      title,
      lang,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationPrompted() {
  const gtmEvent = {
    event: 'userLocationAskedViaPopup',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationGranted() {
  const gtmEvent = {
    event: 'userLocationGranted',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationDeclined() {
  const gtmEvent = {
    event: 'userLocationDeclined',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMChangingLocationDropdown({ location, code }) {
  const gtmEvent = {
    event: 'toggleLocationDropdown',
    attributes: {
      location,
      code,
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentTimeSelectedEvent({
  location,
  hourOfDay,
  selectedDate,
  currentDate,
  daysBetween,
  type = types.book,
  service,
  funnelType,
}) {
  const gtmEvent = {
    event: 'Appointment Time selected',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingFormDisplayedEvent({
  location,
  hourOfDay,
  selectedDate,
  currentDate,
  daysBetween,
  type = types.book,
  service,
  funnelType,
}) {
  const gtmEvent = {
    event: 'Booking Form Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingLeadCreated({ ...eventAttributes }) {
  const gtmEvent = {
    event: 'Lead Created',
    attributes: {
      ptid: 'contentful',
      ...eventAttributes,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentBookedEvent({
  location,
  hourOfDay,
  selectedDate,
  currentDate,
  service,
  daysBetween,
  appointmentId,
  personalDetails,
  type = types.book,
  customer,
  funnelType,
}) {
  const gtmEvent = {
    event: 'Appointment Booked',
    attributes: {
      ptid: 'contentful',
      email: personalDetails.email,
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      appointmentId,
      customer,
      funnelType,
    },
  };
  pushToDataLayer(gtmEvent);
}

export function pushGTMCalendarDisplayedEvent({
  location,
  type = types.book,
  slots = {},
  totalAmountOfSlots,
  days,
  extraDays,
  newSlots,
  service,
  funnelType,
}) {
  const gtmEvent = {
    event: 'Calendar Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      funnelType,
      location,
      slots,
      totalAmount: totalAmountOfSlots,
      days,
      extraDays,
      ...newSlots,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentDetailsDisplayedEvent({ location, service, type = types.book, funnelType }) {
  const gtmEvent = {
    event: 'Appointment Details Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUpdateBookingDisplayedEvent({ location, service, funnelType }) {
  const gtmEvent = {
    event: 'Update Booking Displayed',
    attributes: {
      ptid: 'contentful',
      type: types.reschedule,
      service,
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMLocationsDisplayedEvent({ service, type = types.book, funnelType = 'normal' }) {
  const gtmEvent = {
    event: 'Locations Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMPageView(eventProperties) {
  const countryDomain = process.env.GATSBY_COUNTRY_CODE.slice(-2).toUpperCase();

  const gtmEvent = {
    ...eventProperties,
    countryDomain,
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMErrorEvent({ href }) {
  const gtmEvent = {
    event: 'Error',
    errorCode: 404,
    pageUrl: href,
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMQuestionnaireStartedEvent() {
  const eventName = 'CV-0-Questionnaire_started';

  const gtmEvent = { event: eventName };

  pushToDataLayer(gtmEvent);
}

export function pushGTMQuestionnaireFinishedEvent() {
  const eventName = 'CV-0-Questionnaire_finished';

  const gtmEvent = { event: eventName };

  pushToDataLayer(gtmEvent);
}

export function pushGTMImpressQuestionnaireFinishedEvent() {
  const eventName = 'Impress-Questionnaire_finished';

  const gtmEvent = { event: eventName };

  pushToDataLayer(gtmEvent);
}

export function pushGTMInteractionOnMapEvent(action) {
  const gtmEvent = {
    event: 'Interaction On Map',
    attributes: {
      action,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingFormSubmitted(gtmEvent) {
  pushToDataLayer(gtmEvent);
}

export function pushGTMBookedOutLocationEvent({ location }) {
  const gtmEvent = {
    ...createGTMBookingEventBody('booked out location'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGMTWaitlistOpenedEvent() {
  const gtmEvent = createGTMBookingEventBody('waitlist opened');

  pushToDataLayer(gtmEvent);
}

export function pushGMTWaitlistShownEvent() {
  const gtmEvent = createGTMBookingEventBody('waitlist shown');

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistCompletedEvent(location) {
  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist completed'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistSuccessEvent(location) {
  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist success'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistFailureEvent(location) {
  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist failure'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyStartedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyStarted');

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyDisqualifiedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyDisqualified');
  gtmEvent.eventNonInteraction = 'False';

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyCompletedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyCompleted');
  gtmEvent.eventNonInteraction = 'False';

  pushToDataLayer(gtmEvent);
}

export function pushGTMChooseLocationEvent(location, funnelType = 'normal') {
  const gtmEvent = {
    event: 'gtmEvent',
    eventCategory: 'Booking funnel',
    eventAction: 'location submitted',
    eventNonInteraction: 'False',
    attributes: {
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMLeadCreatedEvent(funnelType = 'normal') {
  const eventName = 'CV-3-lead-created';

  const gtmEvent = { event: eventName, funnelType };

  pushToDataLayer(gtmEvent);
}

export function pushGTMEvent({ eventAction, eventCategory }) {
  if (!eventAction || !eventCategory) return;

  const gtmEvent = {
    event: 'gtmEvent',
    eventCategory,
    eventAction,
    eventNonInteraction: 'False',
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMCESRating({ score, appointmentId }) {
  const gtmEvent = {
    event: 'CESratingSubmitted',
    attributes: {
      score,
      appointmentId,
    },
  };
  pushToDataLayer(gtmEvent);
}

export function pushGTMDayFilterAdded(date) {
  const gtmEvent = {
    event: 'Day Filter Added',
    attributes: {
      dateApiFormat: date.apiFormat,
      dateRenderedFormat: date.formatted,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMDayFilterRemoved(date) {
  const gtmEvent = {
    event: 'Day Filter Removed',
    attributes: {
      dateApiFormat: date.apiFormat,
      dateRenderedFormat: date.formatted,
    },
  };

  pushToDataLayer(gtmEvent);
}
