/**
 * https://mui.com/material-ui/api/paper/
 */
const MuiPaper = {
  defaultProps: {
    elevation: 25,
  },
};

export default MuiPaper;
