/**
 * https://mui.com/material-ui/api/menu-item/
 */
const MuiMenuItem = {
  defaultProps: {
    disableGutters: true,
    disableRipple: true,
    disableTouchRipple: true,
  },
};

export default MuiMenuItem;
