import { typography as coreTypography } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.typography
 */
const typography = {
  global: {
    htmlFontSize: coreTypography.fontSize.root,
    fontFamily: coreTypography.fontFamily.default,

    fontWeightLight: coreTypography.fontWeight.light,
    fontWeightRegular: coreTypography.fontWeight.medium,
    fontWeightMedium: coreTypography.fontWeight.semiBold,
    fontWeightBold: coreTypography.fontWeight.bold,
  },

  responsive: theme => ({
    visibleNav: {
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl3,
    },

    hiddenNav: {
      fontSize: coreTypography.fontSize.lvl7,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl3,
      },
    },

    h1: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl9,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl11,
        lineHeight: coreTypography.lineHeight.lvl7,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl12,
        lineHeight: coreTypography.lineHeight.lvl4,
      },
    },

    h2: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl7,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl6,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl8,
        lineHeight: coreTypography.lineHeight.lvl9,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl10,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },

    h3: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl6,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl11,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl7,
        lineHeight: coreTypography.lineHeight.lvl9,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl8,
        lineHeight: coreTypography.lineHeight.lvl7,
      },
    },

    h4: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl5,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl3,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl6,
        lineHeight: coreTypography.lineHeight.lvl2,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl7,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },

    h5: undefined,
    h6: undefined,

    numberHeading: {
      fontFamily: coreTypography.fontFamily.default,
      fontWeight: coreTypography.fontWeight.semiBold,
      fontSize: coreTypography.fontSize.lvl11,
      lineHeight: coreTypography.lineHeight.lvl4,

      [theme.breakpoints.up('tablet')]: {
        fontWeight: coreTypography.fontWeight.semiBold,
      },
    },

    body1: undefined, // TODO: switch bodyM1 to body1 once migration is complete
    bodyM1: {
      fontSize: coreTypography.fontSize.lvl5,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl10,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl6,
        lineHeight: coreTypography.lineHeight.lvl11,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl7,
        lineHeight: coreTypography.lineHeight.lvl9,
      },
    },

    body2: undefined, // TODO: switch bodyM2 to body2 once migration is complete
    bodyM2: {
      fontSize: coreTypography.fontSize.lvl4,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl9,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl10,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl6,
        lineHeight: coreTypography.lineHeight.lvl11,
      },
    },

    bodyBig: {
      fontSize: coreTypography.fontSize.lvl7,
      lineHeight: coreTypography.lineHeight.lvl7,
      fontFamily: coreTypography.fontFamily.heading,
      fontWeight: coreTypography.fontWeight.bold,
      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl8,
        lineHeight: coreTypography.lineHeight.lvl7,
      },
    },

    caption: {
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl8,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl9,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl10,
      },
    },

    subtitle1: {
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.light,
      lineHeight: coreTypography.lineHeight.lvl4,
      letterSpacing: coreTypography.letterSpacing.xl,
      textTransform: coreTypography.textTransform.uppercase,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl1,
        letterSpacing: coreTypography.letterSpacing.lg,
      },
    },

    subtitle2: {
      fontSize: coreTypography.fontSize.lvl2,
      fontWeight: coreTypography.fontWeight.light,
      lineHeight: coreTypography.lineHeight.lvl3,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl3,
      },
    },

    searchInput: {
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl8,

      [theme.breakpoints.up('tablet')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl9,
      },

      [theme.breakpoints.up('desktop')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl10,
      },
    },

    button: {
      fontWeight: coreTypography.fontWeight.semiBold,
      textTransform: coreTypography.textTransform.uppercase,

      responsive: {
        fontSize: coreTypography.fontSize.lvl3,
        fontWeight: coreTypography.fontWeight.medium,
        lineHeight: coreTypography.lineHeight.lvl8,

        [theme.breakpoints.up('tablet')]: {
          fontSize: coreTypography.fontSize.lvl4,
          lineHeight: coreTypography.lineHeight.lvl9,
        },

        [theme.breakpoints.up('desktop')]: {
          fontSize: coreTypography.fontSize.lvl5,
          lineHeight: coreTypography.lineHeight.lvl10,
        },
      },

      small: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
        letterSpacing: coreTypography.letterSpacing.md,
      },

      medium: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl1,
        letterSpacing: coreTypography.letterSpacing.sm,
      },

      textVariant: {
        fontWeight: coreTypography.fontWeight.bold,

        small: {
          fontSize: coreTypography.fontSize.lvl4,
          lineHeight: coreTypography.lineHeight.lvl1,
          letterSpacing: coreTypography.letterSpacing.xs,
        },

        medium: {
          fontSize: coreTypography.fontSize.lvl6,
          lineHeight: coreTypography.lineHeight.lvl2,
          letterSpacing: coreTypography.letterSpacing.xxs,
        },
      },
    },

    tag: {
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl8,
    },

    overline: undefined,
  }),
};

export default typography;
