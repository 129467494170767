import { DS_shadows as coreShadows } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.shadows
 */

const DS_shadows = {
  buttonsHover: coreShadows.buttonsHover,
  priceCard: coreShadows.priceCard,
  cardsHover: coreShadows.cardsHover,
};

export default DS_shadows;
