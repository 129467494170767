import { createTheme } from '@mui/material/styles';

import fonts from 'mui/assets/fonts';
import { html } from '../styles';

import { border, breakpoints, layout, palette, shadows, spacing, shape, typography } from './tokens/application';
import { DS_breakpoints, DS_palette, DS_spacing, DS_shadows, DS_border, DS_typography } from './DS_Tokens/application';

const extendedBreakpoints = {
  ...breakpoints,
  values: {
    ...breakpoints.values,
    ...DS_breakpoints.values,
  },
  px: {
    ...breakpoints.px,
    ...DS_breakpoints.px,
  },
};

import {
  MuiButton,
  MuiButtonBase,
  MuiDrawer,
  MuiIconButton,
  MuiLink,
  MuiList,
  MuiMenuItem,
  MuiPaper,
  MuiPopover,
} from './tokens/component';

const theme = createTheme({
  border,
  layout,
  palette,
  shape,
  // changed spacing function so that it accepts numbers,
  // and we can use attribute spacing on grid
  spacing,
  typography: typography.global,

  //old and new breakpoints are merged together,
  //change it once legacy breakpoints are no longer necessary
  breakpoints: extendedBreakpoints,

  //new Theme DS
  DS_palette,
  DS_spacing,
  DS_shadows,
  DS_border,
  DS_typography: DS_typography.global,

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fonts}
        ${html}
      `,
    },

    MuiButton,
    MuiButtonBase,
    MuiDrawer,
    MuiLink,
    MuiList,
    MuiMenuItem,
    MuiPaper,
    MuiPopover,
    MuiIconButton,
  },
});

// Typography
theme.typography.visibleNav = typography.responsive(theme).visibleNav;
theme.typography.hiddenNav = typography.responsive(theme).hiddenNav;
theme.typography.numberHeading = typography.responsive(theme).numberHeading;

theme.typography.h1 = typography.responsive(theme).h1;
theme.typography.h2 = typography.responsive(theme).h2;
theme.typography.h3 = typography.responsive(theme).h3;
theme.typography.h4 = typography.responsive(theme).h4;
theme.typography.h5 = typography.responsive(theme).h5;
theme.typography.h6 = typography.responsive(theme).h6;

theme.typography.body1 = typography.responsive(theme).body1;
theme.typography.body2 = typography.responsive(theme).body2;
// TODO: remove bodyM1 and bodyM2 once migration is complete
theme.typography.bodyM1 = typography.responsive(theme).bodyM1;
theme.typography.bodyM2 = typography.responsive(theme).bodyM2;

theme.typography.caption = typography.responsive(theme).caption;

theme.typography.subtitle1 = typography.responsive(theme).subtitle1;
theme.typography.subtitle2 = typography.responsive(theme).subtitle2;

theme.typography.searchInput = typography.responsive(theme).searchInput;

theme.typography.button = typography.responsive(theme).button;
theme.typography.tag = typography.responsive(theme).tag;

theme.typography.bodyBig = typography.responsive(theme).bodyBig;

//New Theme DS
theme.typography.DS_h1 = DS_typography.responsive(theme).DS_h1;
theme.typography.DS_h2 = DS_typography.responsive(theme).DS_h2;
theme.typography.DS_h3 = DS_typography.responsive(theme).DS_h3;
theme.typography.DS_h4 = DS_typography.responsive(theme).DS_h4;
theme.typography.DS_h5 = DS_typography.responsive(theme).DS_h5;

theme.typography.DS_copyBigRegular = DS_typography.responsive(theme).DS_copyBigRegular;
theme.typography.DS_copyBigBold = DS_typography.responsive(theme).DS_copyBigBold;
theme.typography.DS_copyMediumBold = DS_typography.responsive(theme).DS_copyMediumBold;
theme.typography.DS_copyMediumRegular = DS_typography.responsive(theme).DS_copyMediumRegular;
theme.typography.DS_copySmallRegular = DS_typography.responsive(theme).DS_copySmallRegular;
theme.typography.DS_copySmallBold = DS_typography.responsive(theme).DS_copySmallBold;
theme.typography.DS_copyXSmallRegular = DS_typography.responsive(theme).DS_copyXSmallRegular;

theme.typography.DS_labelSmallRegular = DS_typography.responsive(theme).DS_labelSmallRegular;
theme.typography.DS_labelSmallBold = DS_typography.responsive(theme).DS_labelSmallBold;
theme.typography.DS_labelMediumBold = DS_typography.responsive(theme).DS_labelMediumBold;

theme.typography.DS_labelLink = DS_typography.responsive(theme).DS_labelLink;
theme.typography.DS_bigCaptionMedium = DS_typography.responsive(theme).DS_bigCaptionMedium;

// Shadows
theme.shadows = [...theme.shadows, ...shadows];

export default theme;
