import { DS_border as coreBorder } from '../core';
import { DS_palette as corePalette } from '../core';

const DS_border = {
  radius: {
    button: coreBorder.radius.sm,
    highlighted: coreBorder.radius.lg,
    priceCard: coreBorder.radius.xl,
    labelsTags: coreBorder.radius.xxl,
    img: coreBorder.radius.sm,
    dates: coreBorder.radius.md,
    video: {
      xs: coreBorder.radius.sm,
      sm: coreBorder.radius.md,
      lg: coreBorder.radius.xl,
    },
  },
  width: {
    button: coreBorder.width.sm,
  },
  priceCard: `${coreBorder.width.sm} solid ${corePalette.grey[50]}`,
};

export default DS_border;
