const theme = {
  colors: {
    primary: '#23a455',
    accent: '#266ff2',
    error: '#E61E1E',
    text: '#54595f',
    facebook: '#3b5998',
    subheading: '#969696',
    faqBorder: '#e9e9e9',
    white_absolute: '#ffffff',
    gray: '#2A2A2A',
    gray_2: '#dcdcdc',
    gray_3: '#d9d9d9',
    gray_4: '#767676',
    gray_5: '#BDBDBD',
    lightGray: '#f9f9f9',
    lightGray_2: '#F3F3F3',
    lightGray_3: '#767676',
    lightGray_4: '#ebebeb',
    lightGray_5: '#AFAFAF',
    lightGray_6: '#E1E1E1',
    lightGray_7: '#DDDDDD',
    lightGray_8: '#f0f0f0',
    lightGray_9: '#f2f2f2',
    lightGray_10: '#dedede',
    darkGray: '#808080',
    darkGray_2: '#8C8C8C',
    darkGray_3: '#818181',
    darkGray_5: '#B3B3B3',
    darkGray_6: '#EBEBEB',
    darkGray_7: '#767676',
    darkGray_8: '#7A7A7A',
    darkGray_9: '#979797',
    darkGray_10: '#4F4F4F',
    lightBlue: '#D3E3FF',
    lightBlue_2: '#F0F5FF',
    yellow: '#FFD000',
    black: '#202020',
    black_2: '#363636',
    black_absolute: '#000000',
  },
  sizes: {
    auto: 'auto',
  },
  space: [0, 5, 10, 15, 26, 40],
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px', '1870px'],

  // Rebass theme variants
  variants: {
    container: {
      mx: 'auto',
      px: 3,
      maxWidth: ['100%', '540px', '720px', '960px', '1140px'],
    },
  },

  maxWidths: {
    xl: '1440px',
  },

  // Fonts
  fonts: {
    body: 'OpenSans',
    accent: 'Georgia',
  },

  // z-index values
  zIndex: {
    small: 10,
    fixedButton: 1000,
    navbar: 1050,
    sideDrawer: 1100,
    modal: 1200,
    overlay: 1300,
    cookieBanner: 1400,
  },

  padding: {
    main: {
      mobile: 60,
      mobileNew: 112,
      desktop: 80,
    },
  },

  gap: {
    component: {
      mobile: '50px',
      desktop: '100px',
    },
  },
};

module.exports = theme;
