const typography = {
  fontFamily: {
    default: [
      'OpenSans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    heading: ['Georgia', 'Times', '"Times New Roman"', 'serif'].join(','),
  },

  fontSize: {
    root: 16,
    lvl1: '0.75rem', // 12px
    lvl2: '0.8125rem', // 13px
    lvl3: '0.875rem', // 14px
    lvl4: '1rem', // 16px
    lvl5: '1.125rem', // 18px
    lvl6: '1.25rem', // 20px
    lvl7: '1.5rem', // 24px
    lvl8: '1.75rem', // 28px
    lvl9: '2rem', // 32px
    lvl10: '2.25rem', // 36px
    lvl11: '2.5rem', // 40px
    lvl12: '3rem', // 48px
  },

  fontWeight: {
    light: 300,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },

  lineHeight: {
    lvl1: 1.1875,
    lvl2: 1.2,
    lvl3: 1.22,
    lvl4: 1.25,
    lvl5: 1.3125,
    lvl6: 1.33,
    lvl7: 1.35,
    lvl8: 1.4285,
    lvl9: 1.5,
    lvl10: 1.55,
    lvl11: 1.6,
  },

  letterSpacing: {
    xxs: '0.028em',
    xs: '0.035em',
    sm: '0.1875em',
    md: '0.231em',
    lg: '0.3125em',
    xl: '0.4167em',
  },

  textTransform: {
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
  },
};

export default typography;
