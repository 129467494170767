const palette = {
  black: {
    900: '#000000',
  },

  white: {
    100: '#FFFFFF',
  },

  gray: {
    100: '#F6F6F6',
    200: '#D8D8D8',
    300: '#ECECEC',
    400: '#969696',
    500: '#767676',
  },

  blue: {
    100: '#87AFF8',
    200: '#266FF2',
    300: '#0059FA',
  },

  red: {
    400: '#d32f2f',
  },
};

export default palette;
