import { DS_typography as coreTypography } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.typography
 */
const DS_typography = {
  global: {
    htmlFontSize: coreTypography.fontSize.root,
    fontFamily: coreTypography.fontFamily.default,
  },

  responsive: theme => ({
    DS_h1: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl8,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl11,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl11,
        lineHeight: coreTypography.lineHeight.lvl13,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl14,
        lineHeight: coreTypography.lineHeight.lvl14,
      },
    },
    DS_h2: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl7,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl9,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl10,
        lineHeight: coreTypography.lineHeight.lvl12,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl12,
        lineHeight: coreTypography.lineHeight.lvl13,
      },
    },
    DS_h3: {
      fontFamily: coreTypography.fontFamily.heading,
      fontSize: coreTypography.fontSize.lvl5,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl7,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl8,
        lineHeight: coreTypography.lineHeight.lvl11,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl9,
      },
    },

    DS_h4: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl9,
      fontWeight: coreTypography.fontWeight.extraBold,
      lineHeight: coreTypography.lineHeight.lvl10,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl11,
        lineHeight: coreTypography.lineHeight.lvl13,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl13,
        lineHeight: coreTypography.lineHeight.lvl13,
      },
    },

    DS_h5: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl6,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl8,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl9,
        lineHeight: coreTypography.lineHeight.lvl11,
      },
    },

    DS_copyBigRegular: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl4,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl7,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl5_5,
      },
    },
    DS_copyBigBold: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl4,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl8,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl5_5,
      },
    },
    DS_copyMediumBold: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },
    DS_copyMediumRegular: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl3,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl5,

      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },

    DS_copySmallRegular: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl2,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
      },
    },

    DS_copySmallBold: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl2,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
      },
    },

    DS_copyXSmallRegular: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl4,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
      },
    },
    DS_labelSmallRegular: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl1,
      letterSpacing: coreTypography.letterSpacing.lvl2,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
        letterSpacing: coreTypography.letterSpacing.lvl1,
      },
    },

    DS_labelSmallBold: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl1,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl1,
      letterSpacing: coreTypography.letterSpacing.lvl2,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl2,
        lineHeight: coreTypography.lineHeight.lvl3,
        letterSpacing: coreTypography.letterSpacing.lvl1,
      },
    },
    DS_labelMediumBold: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl2,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl1,
      letterSpacing: coreTypography.letterSpacing.lvl2,
      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl5,
        letterSpacing: coreTypography.letterSpacing.lvl1,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: coreTypography.fontSize.lvl5,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },
    DS_labelLink: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl2,
      fontWeight: coreTypography.fontWeight.bold,
      lineHeight: coreTypography.lineHeight.lvl1,

      [theme.breakpoints.up('md')]: {
        fontSize: coreTypography.fontSize.lvl4,
        lineHeight: coreTypography.lineHeight.lvl6,
      },
    },
    DS_bigCaptionMedium: {
      fontFamily: coreTypography.fontFamily.default,
      fontSize: coreTypography.fontSize.lvl5,
      fontWeight: coreTypography.fontWeight.medium,
      lineHeight: coreTypography.lineHeight.lvl6,

      [theme.breakpoints.up('lg')]: {
        fontWeight: coreTypography.fontWeight.bold,
      },
    },
  }),
};

export default DS_typography;
