import { border as coreBorder } from '../core';

const border = {
  radius: {
    dialog: coreBorder.radius.sm,
  },
  width: {
    button: coreBorder.width.thin,
    container: coreBorder.width.thin,
    divider: coreBorder.width.thin,
    linkUnderline: coreBorder.width.thick,
  },
};

export default border;
