/**
 * https://mui.com/material-ui/api/icon-button/
 */
const MuiIconButton = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
};

export default MuiIconButton;
