const layout = {
  container: {
    maxWidth: '90rem', // 1440px
    maxWidthLg: '80rem', // 1280px
    maxWidthMd: '60rem', // 960px
    maxWidthSm: '40rem', // 640px
  },
};

export default layout;
