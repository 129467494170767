import { tag, data, init } from '@blotoutio/edgetag-sdk-js';

import { getPtsrcParam, types } from './gtm';
import { getCookie } from './cookies';

/**
 * Initialize EdgeTag
 * https://app.edgetag.io/docs/step-by-step#init
 */
export const initializeEdgeTag = () => {
  if (isEdgeTagEnabled()) init({ edgeURL: process.env.GATSBY_EDGETAG_SITE_SUBDOMAIN, disableConsentCheck: true });
};

/**
 * Return true if EdgeTag subdomain is given and the push is enabled.
 * @return {boolean}
 */
export const isEdgeTagEnabled = () => {
  return process.env.GATSBY_EDGETAG_SITE_SUBDOMAIN && process.env.GATSBY_EDGETAG_PUSH_ENABLED === 'true';
};

/**
 * Pushing events to EdgeTag
 * https://app.edgetag.io/docs/step-by-step#tag
 * @param {Object} event
 */
export const pushEdgeTagEvent = event => {
  if (event?.name && isEdgeTagEnabled()) tag(event.name, event?.attributes, event?.providers);
};

/**
 * Pushing user data to EdgeTag
 * https://app.edgetag.io/docs/verification
 * @param {Object} personalDetails
 */
export const addUserDataEdgeTag = personalDetails => {
  if (isEdgeTagEnabled())
    data({
      email: personalDetails.email,
      phone: personalDetails.phone,
      firstName: personalDetails.firstName,
      lastName: personalDetails.lastName,
      country: personalDetails.country,
    });
};

/**
 * Push 'scan_appointment_booked' event to EdgeTag and all the other events required at this point
 * @param {Object} eventDetails
 */
export const pushEdgeTagAppointmentBookedEvents = ({
  location,
  hourOfDay,
  selectedDate,
  currentDate,
  service,
  daysBetween,
  appointmentId,
  pickupRequested,
  returnTripRequested,
  type = types.book,
  customer,
  funnelType,
  personalDetails,
}) => {
  const event = {
    name: 'scan_appointment_booked',
    attributes: {
      bookingType: type,
      hourofDay: hourOfDay,
      location,
      serviceType: service,
      daysBetween,
      appointmentID: appointmentId,
      returningCustomer: customer,
      ptsrc: getPtsrcParam(),
      selectedDate,
      currentDate,
      pickupRequested,
      returnTripRequested,
      funnelType,
      FB_fbc: getCookie('_fbc'),
      FB_fbp: getCookie('_fbp'),
    },
    providers: {
      blotoutCloud: true,
    },
  };

  addUserDataEdgeTag(personalDetails);
  pushEdgeTagEvent(event);
  pushAppointmentBookedMetaEvent();
  pushAppointmentBookedSnapChatEvent();

  if (personalDetails.country === 'SE') pushAppointmentBookedGoogleAdsSEEvent();

  if (['DE', 'AT', 'SE', 'FR', 'IT', 'ES', 'PL', 'NL', 'PT'].indexOf(personalDetails.country) > -1)
    pushAppointmentBookedTikTokEvent(personalDetails);
};

/**
 * Push Google Ads Event 'scan_appointment_booked' for SE
 */
const pushAppointmentBookedGoogleAdsSEEvent = () => {
  const event = {
    name: 'scan_appointment_booked',
    attributes: {
      orderId: getCookie('eventID'),
    },
    providers: {
      googleAds: true,
    },
  };

  pushEdgeTagEvent(event);
};

/**
 * Push FB Meta Event 'Schedule' on appointment booked
 */
const pushAppointmentBookedMetaEvent = () => {
  const event = {
    name: 'Schedule',
    attributes: {
      eventId: getCookie('eventID'),
    },
    providers: {
      facebook: true,
    },
  };

  pushEdgeTagEvent(event);
};

/**
 * Push Snapchat 'SIGN_UP' event on appointment booked
 */
const pushAppointmentBookedSnapChatEvent = () => {
  const event = {
    name: 'SIGN_UP',
    attributes: {
      eventId: getCookie('eventID'),
    },
    providers: {
      snapchat: true,
    },
  };

  pushEdgeTagEvent(event);
};

/**
 * Push Tiktok 'SubmitForm' event on appointment booked
 */
const pushAppointmentBookedTikTokEvent = () => {
  const event = {
    name: 'SubmitForm',
    attributes: {
      eventId: getCookie('eventID'),
    },
    providers: {
      tiktok: true,
    },
  };

  pushEdgeTagEvent(event);
};
