const spacing = {
  rem: {
    xxs: '0.625rem', // 10px
    xs: '1.25rem', // 20px
    sm: '1.875rem', // 30px
    md: '2.5rem', // 40px
    lg: '3.75rem', // 60px
    xl: '5rem', // 80px
    xxl: '8.75rem', // 140px
  },

  // Should be equivalent to rems above
  px: {
    xxs: '10px',
    xs: '20px',
    sm: '30px',
    md: '40px',
    lg: '60px',
    xl: '80px',
    xxl: '140px',
  },

  em: {
    xxs: '0.357em',
    xs: '0.5em',
    sm: '1em',
    md: '1.15em',
    lg: '2.91em',
    xl: '3.125em',
    xxl: '3.84em',
  },
};

export default spacing;
