const DS_spacing = {
  rem: {
    xxxs: '0.25rem',
    xxs: '0.5rem',
    xxsx: '0.75rem',
    xs: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
    lgx: '2rem',
    xl: '2.5rem',
    xlx: '3.5rem',
    xxl: '5rem',
    xxxl: '7.5rem',
  },

  // Should be equivalent to rems above
  px: {
    xxxs: '4px',
    xxs: '8px',
    xxsx: '12px',
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '28px',
    lgx: '32px',
    xl: '40px',
    xlx: '56x',
    xxl: '80px',
    xxxl: '120px',
  },
};

export default DS_spacing;
