import { DS_palette as corePalette } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
 */
const DS_palette = {
  primary: {
    black: corePalette.black[900],
    white: corePalette.white[50],
    transparent: corePalette.white[0],
    main: corePalette.blue[500],
  },
  buttons: {
    enabled: corePalette.blue[500],
    hover: corePalette.blue[500],
    pressed: corePalette.blue[600],
    disabled: corePalette.grey[200],
    stroke: corePalette.blue[100],
    error: corePalette.states.error,
    text: {
      pressed: corePalette.blue[50],
      hover: corePalette.blue[50],
    },
  },

  border: {
    light: corePalette.grey[50],
    enabled: corePalette.blue[500],
    quote: corePalette.grey[200],
    form: corePalette.grey[100],
  },

  text: {
    primary: corePalette.black[900],
    secondary: corePalette.grey[300],
  },

  label: {
    text: corePalette.mint[200],
    bgColor: corePalette.mint[50],
  },
  tag: {
    text: corePalette.flamingo[200],
    bgColor: corePalette.flamingo[50],
  },
  cards: {
    attention: corePalette.flamingo[100],
  },
  background: {
    light: corePalette.blue[50],
    lightPink: corePalette.pink[50],
    mediumPink: corePalette.pink[100],
    lightBlue: corePalette.blue[100],
    lightgrey: corePalette.grey[100],
  },
  state: {
    success: corePalette.mint[200],
    warning: corePalette.marketing['yellow'],
    error: corePalette.states.error,
    disabled: corePalette.grey[100],
  },
  seasonal: {
    pink: corePalette.marketing['pink'],
  },
};

export default DS_palette;
