/**
 * https://mui.com/material-ui/api/button-base/
 */
const MuiButtonBase = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
};

export default MuiButtonBase;
