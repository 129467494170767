const DS_typography = {
  fontFamily: {
    default: [
      'OpenSans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    heading: ['Georgia', 'Times', '"Times New Roman"', 'serif'].join(','),
  },

  fontSize: {
    root: 16,
    lvl1: '0.75rem', // 12px
    lvl2: '0.875rem', // 14px
    lvl3: '1rem', // 16px
    lvl4: '1.125rem', // 18px
    lvl5: '1.5rem', // 24px
    lvl5_5: '1.625rem', //26px
    lvl6: '1.75rem', // 28px
    lvl7: '1.875rem', // 30px
    lvl8: '2.25rem', // 36px
    lvl9: '2.5rem', // 40px
    lvl10: '3rem', // 48px
    lvl11: '3.375rem', // 54px
    lvl12: '3.75rem', // 60px
    lvl13: '4rem', // 64px
    lvl14: '4.5rem', // 72px
  },

  fontWeight: {
    medium: 500,
    bold: 700,
    extraBold: 800,
  },

  lineHeight: {
    lvl1: '14px',
    lvl2: '18px',
    lvl3: '20px',
    lvl4: '22px',
    lvl5: '24px',
    lvl5_5: '26px',
    lvl6: '28px',
    lvl7: '32px',
    lvl8: '34px',
    lvl9: '38px',
    lvl10: '44px',
    lvl11: '48px',
    lvl12: '56px',
    lvl13: '68px',
    lvl14: '82px',
  },

  letterSpacing: {
    lvl1: '0.24px',
    lvl2: '1px',
  },

  textTransform: {
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
  },
};

export default DS_typography;
