const DS_breakpoints = {
  unit: 'em',
  xs: 0,
  sm: 0,
  md: 45, //720px
  lg: 80, //1280px
  xl: 96.25, //1540px
};

export default DS_breakpoints;
