import { palette as corePalette } from '../core';

/**
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
 */
const palette = {
  action: {
    active: undefined,
    hover: undefined,
    disabled: undefined,
    disabledBackground: undefined,
    selected: undefined,
    focus: undefined,
  },

  background: {
    gray: {
      light: corePalette.gray[100],
      dark: corePalette.gray[300],
    },
    blue: {
      light: corePalette.blue[100],
    },
  },

  border: {
    black: corePalette.black[900],
    gray: {
      light: corePalette.gray[100],
      medium: corePalette.gray[300],
    },
    blue: corePalette.blue[300],
  },

  common: {
    black: corePalette.black[900],
    white: corePalette.white[100],
  },

  divider: corePalette.gray[200],

  primary: {
    main: corePalette.blue[200],
    action: corePalette.blue[300],
    disabled: corePalette.blue[100],
    light: undefined,
    dark: corePalette.gray[500],
    contrastText: corePalette.white[100],
    error: corePalette.red[400],
  },

  secondary: {
    main: corePalette.black[900],
    action: corePalette.blue[300],
    disabled: corePalette.gray[400],
    light: undefined,
    dark: undefined,
    contrastText: corePalette.white[100],
  },

  text: {
    primary: corePalette.black[900],
    secondary: corePalette.gray[400],
    disabled: corePalette.gray[400],
  },
};

export default palette;
